import { Component, Vue } from "vue-property-decorator";
import DfPromotion from "@/vue/domain/promotion/df-promotion";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfStore from "@/vue/domain/store/df-store";
const DfSectionLinksComponent = () => Utils.externalComponent2("df-section-links");
const DfPromotionCardComponent = () => Utils.externalComponent2("df-promotion-card");
const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");
const DfHeaderMobileComponent = () => Utils.externalComponent2("df-header-mobile");

@Component({
  components: {
    DfHeaderMobile: DfHeaderMobileComponent,
    DfFooter: () => Utils.externalComponent2("df-footer"),
    DfStoreCard: DfStoreCardComponent,
    DfPromotionCard: DfPromotionCardComponent,
    DfSectionLinks: DfSectionLinksComponent,
  },
})
export default class DfPagePromotionsComponent extends Vue {
  get promotions(): Array<DfPromotion> {
    return this.$store.getters.promotions;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get showBreadcrumb(): boolean {
    return Utils.showBreadcrumb();
  }

  get promotionsFlyer(): Array<DfPromotion> {
    return this.$store.getters.promotions.filter((promotion: DfPromotion) => {
      return Utils.getPropertyValue(promotion, Utils.PROPERTY_PROMOTION_TYPE, "LIST", { key: "FLYER" }).key === "FLYER";
    }) ;
  }

  get promotionsCatalog(): Array<DfPromotion> {
    return this.$store.getters.promotions.filter((promotion: DfPromotion) => {
      return Utils.getPropertyValue(promotion, Utils.PROPERTY_PROMOTION_TYPE, "LIST", { key: "FLYER" }).key === "CATALOG";
    }) ;
  }

  get promotionsMagazine(): Array<DfPromotion> {
    return this.$store.getters.promotions.filter((promotion: DfPromotion) => {
      return Utils.getPropertyValue(promotion, Utils.PROPERTY_PROMOTION_TYPE, "LIST", { key: "FLYER" }).key === "MAGAZINE";
    }) ;
  }

  private goToStorePage(store: DfStore) {
    this.$router.push({ name: "store", params: { storeAlias: store.alias } }).catch((err) => err);
  }

  private openMap() {
    window.open(`${Utils.GOOGLE_MAP_URL}${Utils.storeGoogleMapsCoordinates(this.currentStore)}`, "_blank");
  }
}
